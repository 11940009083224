import {Box, Button, InputAdornment, TextField, Typography} from "@mui/material";
import {objectApi} from "application/entities/dataApi";
import {getCurrencySymbol, moneyFormatter} from "application/utils/formatters.utils";
import {useAppToast} from "components/live/AppToast";
import {FieldZone} from "components/templates/InfoBlock";
import React from "react";
import {useForm} from "react-hook-form";
import {useOpenDialogWindow} from "components/live/DialogWindow";
import {CPMInfos} from "./CPMInfos";
import {CPMFormProps} from "./CPMFormType";
import {AppTip} from "../../pages/AudienceBuilder/components/AudienceBuilderAppTip";

export const AdditionnalFixedCPM = (props: CPMFormProps) => {
    const {
        accountConfParams,
        currency,
        currencyRateToUSD,
        setFormSelected,
        brandId,
        fetchAccountConfParameters
    } = props;
    const appToast = useAppToast();
    const dialogWindow = useOpenDialogWindow();

    React.useEffect(() => {
        if (Object.keys(accountConfParams).length) {
            reset({
                agency_additional_cpm: (accountConfParams.agency_additional_cpm ?? 0) / (currencyRateToUSD ?? 1),
            });
        }
    }, [accountConfParams]);

    const formCPM = useForm({
        mode: "all",
        defaultValues: {
            agency_additional_cpm: 0,
        },
    });

    const {
        watch,
        handleSubmit,
        reset,
        formState: {isValid, isDirty},
    } = formCPM;

    const agency_additional_cpm = formCPM.register("agency_additional_cpm", {
        required: true,
        min: 0,
        max: 10,
        valueAsNumber: true

    });

    const fixedFee = watch("agency_additional_cpm") || 0;

    const calculateCPM = (value: number) => {
        return value * 3000;
    };


    const accountApi = new objectApi.accounts();
    const onSubmit = (data: any) => {
        console.log('data', data.agency_additional_cpm, currencyRateToUSD)
        const additionalCPMUSD = data.agency_additional_cpm * (currencyRateToUSD ?? 1)
        accountApi
            .postCustomerCreateUpdateAccountConfParameters(brandId, {
                paramName: "agency_additional_cpm",
                paramValue: additionalCPMUSD
            })
            .then((res) => {
                appToast.success({
                    message: "CPM increase amout updated",
                });
                fetchAccountConfParameters(brandId);
                formCPM.reset();
            })
            .catch((e) => {
                appToast.error({
                    message: "Error, please try again",
                });
            });
    };
    const onError = (errors: any, e: any) => console.log(errors, e);

    const handleRemoveManagedCPM = () => {
        dialogWindow.open({
            dialogId: "removeManagedCPM",
            buttonActions: {acceptButton: true, cancelButton: true},
            title: "Remove your managed CPM?",
            description: "It will delete the CPM extra cost",
            icon: "Trash",
            onAccept: removeManagedCPM,
        });
    };

    const removeManagedCPM = () => {
        accountApi
            .postCustomerCreateUpdateAccountConfParameters(brandId, {
                paramName: "agency_additional_cpm",
                paramValue: 0,
            })
            .then((res) => {
                appToast.success({
                    message: "Managed CPM has been removed",
                });
                fetchAccountConfParameters(brandId);
                setFormSelected(null);
            });
    };

    return (
        <>
            <form onSubmit={(e) => e.preventDefault()}>
                <CPMInfos/>
                <Box textAlign="center" mt={2}>

                    <FieldZone title={<Box sx={{display: 'flex', gap: '0.5rem', mx: 'auto', justifyContent: 'center'}}>
                        CPM increase amount
                        <AppTip title="CPM increase amount"
                                text="This is an additional CPM which your agency would earn, above
the dynamic CPM generated by the Evorra platform"/>
                    </Box>} code={"agency_additional_cpm"}>
                        <TextField
                            type="number"
                            {...agency_additional_cpm}
                            error={!!formCPM.formState?.errors?.agency_additional_cpm}

                            inputProps={{style: {textAlign: "left"}}}
                            InputProps={{
                                sx: {textAlign: "left", marginBottom: "0px"},
                                startAdornment: (
                                    <InputAdornment position="start" sx={{pl: "10px", mr: "0"}}>
                                        {getCurrencySymbol(currency)}
                                    </InputAdornment>
                                ),
                            }}
                        />

                    </FieldZone>
                    {!!formCPM.formState?.errors?.agency_additional_cpm && (
                        <Typography color="error" mt={-2} mb={2}>
                            The amount should be between 0 and 10
                        </Typography>
                    )}
                </Box>
                {(isValid || !isDirty) && fixedFee > 0 && (
                    <Box maxWidth={650} mx="auto">

                        <Typography variant="body1" mb={1} textAlign="left">


                            You added {moneyFormatter(fixedFee, {currency: currency})} to the base CPM. Therefore, if
                            the audience generates 3M impressions, your
                            agency will earn an
                            extra {moneyFormatter(calculateCPM(fixedFee), {currency: currency})} from this audience.


                        </Typography>
                        <Typography variant="body1" mb={4} textAlign="left" fontStyle="italic" color="#7587a3"
                                    sx={{borderTop: "1px solid #ececec", paddingTop: "0.5rem"}}>
                            Note: Evorra increases the actual CPM for the brand by more than the amount entered to cover
                            data provider revenue share and technology fees. Ensure your CPM increase doe not adversely
                            inflate the price of the data CPM for this brand.
                        </Typography>
                    </Box>
                )}

                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingInline: "1rem",
                    paddingBottom: "0.5rem"
                }}>
                    <div className={"flex-h gap-small"}>
                        {fixedFee === 0 ? (
                            <Button onClick={() => setFormSelected(null)} color={"error"} variant={"outlined"}>
                                Cancel
                            </Button>
                        ) : (
                            <Button onClick={() => handleRemoveManagedCPM()} color={"error"} variant={"outlined"}>
                                Remove managed CPM
                            </Button>
                        )}
                    </div>
                    <div>
                        <Button form={"updateBrand"} disabled={!isValid || !isDirty} variant={"contained"}
                                onClick={handleSubmit(onSubmit, onError)}>
                            Save
                        </Button>
                    </div>
                </div>
            </form>
        </>
    );
};
